.sectionTitle {
    @apply flex items-center uppercase relative whitespace-nowrap;
}
.sectionTitle:not(.before-blue-300)::before {
    transform: translateY(0.5px);
    height: 65%;
    @apply absolute left-0 w-1 bg-iseki-blue-400 content-[''];
}
.sectionTitle.before-blue-300::before {
    transform: translateY(0.5px);
    height: 65%;
    @apply absolute left-0 w-1 bg-iseki-blue-300 content-[''];
}
.sectionTitle .title {
    letter-spacing: 0.48px;
    @apply font-Oswald text-xl lg:text-2xl font-semibold pl-5;
}