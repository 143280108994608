.first-letter-uppercase:first-letter {
    text-transform: uppercase;
}
.spacing-0 {
    letter-spacing: 0;
}
.spacing-32 {
    letter-spacing: 0.32px;
}
.spacing-40 {
    letter-spacing: 0.40px;
}
.spacing-67 {
    letter-spacing: 0.67px;
}
.spacing-78 {
    letter-spacing: 0.78px;
}
.spacing-89 {
    letter-spacing: 0.89px;
}
.clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
