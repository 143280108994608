.inputHolder {
    @apply flex flex-wrap items-center relative text-sm mb-6;
}
.inputHolder label {
    @apply absolute top-0 bg-white left-3 -translate-y-2/4 px-1;
}
.inputHolder label.text-xs {
    letter-spacing: 0.67px;
}
.inputHolder input, .inputHolder select, .inputHolder textarea {
    @apply flex-grow border rounded-md pl-3;
}
.inputSubtitle {
    letter-spacing: 0.67px;
    @apply basis-full pl-3 pt-1.5 text-xs;
}

.inputHolder input[type="number"].appearance-none::-webkit-inner-spin-button,
.inputHolder input[type="number"].appearance-none::-webkit-outer-spin-button, input[type='number'].appearance-none {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
    margin: 0;
}