.userLogin {
    @apply min-h-screen flex flex-col items-center bg-iseki-blue-400;
}

.userLogin .login {
    @apply w-[340px] sm:w-[556px];
}

.userLogin .copyright {
    letter-spacing: 0.67px;
    @apply text-iseki-blue-200 text-xs text-center mt-auto px-6 py-6;
}

.login {
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
    @apply flex flex-col bg-white rounded-tl-md rounded-tr-md sm:mt-10 rounded-bl-md rounded-br-md;
}

.login .logoSlogan {
    @apply flex bg-iseki-blue-400/60 py-5 sm:h-[168px];
}

.login .sloganWrapper {
    @apply flex flex-col justify-center items-center w-full sm:items-start;
}

.login .sloganWrapper img {
    @apply w-[230px];
}

.login form {
    @apply flex flex-col px-4 sm:px-24 py-11;
}

.login .slogan {
    letter-spacing: 0.98px;
    @apply text-white text-lg pt-1.5;
}