html {
    @media screen and (max-width: 374px) {
        font-size: clamp(12px, calc(14 * 100dvw / 375), 14px);
    }

    @media screen and (min-width: 1440px) and (max-width: 1649px) {
        font-size: clamp(14px, calc(16 * 100dvw / 1650), 16px);
    }
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    letter-spacing: 0.78px;

    @apply font-Lato bg-iseki-blue-200 text-iseki-grey-600;
}

#root {
    @apply min-h-screen;
}
