.link-small-blue,
.link-small-white {
    @apply text-sm underline;
}

.link-small-blue {
    @apply text-iseki-blue-400;
}

.link-small-white {
    @apply text-white
}