.page-loading {
    @apply block w-60 h-24 mx-auto mt-60;
}

.page-loading:after {
    content: " ";
    @apply block w-24 h-24 border-8 border-gray-800 border-l-transparent border-r-transparent mx-auto rounded-full;
    animation: page-loading 1.5s linear infinite;
}

@keyframes page-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
