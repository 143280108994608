.checkbox input {
    opacity: 0;
}

.checkbox label {
    @apply flex items-center z-10 cursor-pointer;
}

.checkbox .checkmark {
    @apply block rounded-sm w-5 h-5 bg-iseki-blue-300/30 transition-all flex-shrink-0 self-start;
}
.checkbox.disabled .checkmark {
    @apply block rounded-sm w-5 h-5 bg-iseki-grey-300/30 transition-all;
    @apply flex items-center z-10 cursor-not-allowed;
}

.checkbox.is-checked .checkmark.checkmark-number {
    @apply bg-iseki-blue-400 flex items-center justify-center text-white;
}

.checkbox.is-checked.disabled .checkmark.checkmark-number {
    @apply bg-iseki-grey-400 flex items-center justify-center text-white;
}

.checkbox.is-checked .checkmark:not(.checkmark-number) {
    @apply bg-center bg-no-repeat bg-iseki-blue-400;
    background-image: url(./assets/check.svg);
    background-size: 12px 8px;
}
.checkbox.is-checked.disabled .checkmark:not(.checkmark-number) {
    @apply bg-center bg-no-repeat bg-iseki-grey-400;
    background-image: url(./assets/check.svg);
    background-size: 12px 8px;
}

.checkbox label span {
    @apply text-sm ml-3;
}