.page-cms-renderer h1 {
    @apply text-3xl font-bold font-Oswald uppercase;
}

.page-cms-renderer h2 {
    @apply text-xl font-bold font-Oswald uppercase;
}

.page-cms-renderer h3 {
    @apply font-bold font-Oswald;
}

.page-cms-renderer h4 {
    @apply font-bold text-iseki-blue-400;
}

.page-cms-renderer h5 {
    @apply text-sm font-bold font-Oswald;
}

.page-cms-renderer h6 {
    @apply text-sm font-bold;
}

.page-cms-renderer ol {
    @apply pl-10;
}

.page-cms-renderer ul {
    @apply pl-10;
}

.page-cms-renderer li {
    @apply list-disc;
}

.page-cms-renderer ol li {
    @apply list-decimal;
}

.page-cms-renderer p {
    @apply text-sm;
}

.page-cms-renderer a {
    @apply underline;
}

.page-cms-renderer table td {
    @apply border px-2 py-1;
}

.page-cms-renderer a.btn-blue {
    @apply bg-iseki-blue-400 text-white px-14 py-3 font-bold hover:bg-iseki-blue-500 transition-colors no-underline rounded-full text-base cursor-pointer;
}

.page-cms-renderer a.btn-gray {
    @apply bg-iseki-grey-200 text-iseki-blue-500 px-14 py-3 font-bold hover:bg-iseki-grey-300 transition-colors no-underline rounded-full text-base cursor-pointer;
}

.page-cms-renderer img, .page-cms-renderer video, .page-cms-renderer iframe {
    @apply rounded-md block;
}

.page-cms-renderer img[style*="float: left;"], .page-cms-renderer video[style*="float: left;"], .page-cms-renderer iframe[style*="float: left;"] {
    @apply mr-10;
}


.page-cms-renderer img[style*="float: right;"], .page-cms-renderer video[style*="float: right;"], .page-cms-renderer iframe[style*="float: right;"] {
    @apply ml-10;
}

.page-cms-renderer .download-block-link a {
    @apply p-10 bg-iseki-blue-400 text-white cursor-pointer rounded-md no-underline my-4 flex gap-10 items-center pr-20;
}

.page-cms-renderer .download-block-link img {
    @apply rounded-none;
}
