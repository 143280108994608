/* Google font */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&family=Oswald:wght@600&display=swap');

/* Google tailwind base / components / utilities classes */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Custom Css files */
@import './styles/body.css';
@import './styles/inputs.css';
@import './styles/links.css';
@import './styles/transitions.css';
@import './styles/scrollbar.css';
@import './styles/typos.css';
@import './styles/outline.css';
@import './styles/shadows.css';

/* make sure to put utilities last */
@import 'tailwindcss/utilities';

@import './components/Smart/PageCms/PageCms.css';

