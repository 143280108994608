.button {
    letter-spacing: 0.89px;

    @apply flex items-center justify-center rounded-full text-base font-bold px-6 transition;
}

.button.extraTiny {
    letter-spacing: 0.78px;

    @apply h-[26px] text-sm font-normal px-3;
}